<template>
  <section>
    <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">
      <span class="my-1 d-block">{{ $t('Exercise categories') }}</span>
      <v-select :label="$t('Choose')" v-model="categoryId" outlined
                required single-line small-chips :loading="categoryLoading"
        :no-data-text="$t('No data')" :items="categoryList" item-text="name" item-value="id" :rules="[(v) => !!v]">
      </v-select>

      <span class="my-1 d-block">{{ $t('Exercise type') }}</span>
      <v-select :label="$t('Choose')" v-model="type" outlined required single-line
                small-chips :loading="typeLoading" return-object
        :no-data-text="$t('No data')" :items="typeList" item-text="value" :rules="[(v) => !!v]">
      </v-select>

      <span class="my-1 d-block">{{ $t('Exercise title') }}</span>
      <v-text-field outlined single-line v-model="title" type="text" :rules="[(v) => !!v]" required counter="128" />

      <span class="my-1 d-block">{{ $t('Exercise description') }}</span>
      <v-textarea outlined v-model="description" type="text" counter="600" :rules="descriptionRules" required />
      
<!--      <v-expansion-panels flat accordion>-->
<!--        <v-expansion-panel>-->
<!--          <v-expansion-panel-header class="px-0">{{ $t('Advanced') }}</v-expansion-panel-header>-->
<!--          <v-expansion-panel-content>-->
<!--            <span class="mt-1 d-block f15">{{ $t('Follow up') }}</span>-->
<!--            <span class="mt-4 primary&#45;&#45;text f14 d-block">{{ $t('If your client does not do his exercise, follow up')-->
<!--            }}</span>-->

<!--            <div class="w-100 d-block mt-4">-->
<!--              <span class="d-inline-block ms-0 me-2">{{ $t('Follow up once every') }}</span>-->
<!--              <v-otp-input-->
<!--                length="1"-->
<!--                type="number"-->
<!--                v-model="followUpCount"-->
<!--                hide-spin-buttons-->
<!--                class="d-inline-block w-20">-->
<!--              </v-otp-input>-->
<!--              <span class="d-inline-block ms-2 me-0">{{ $t('days') }}</span>-->
<!--            </div>-->

<!--            <span class="mt-2 mb-1 d-block">{{ $t('Follow up text') }}</span>-->
<!--            <v-textarea outlined v-model="followUpText" type="text" counter="255" :rules="followUpTextRules" />-->
<!--          </v-expansion-panel-content>-->
<!--        </v-expansion-panel>-->
<!--      </v-expansion-panels>-->

      <div class="mt-1 text-center">
        <v-btn color="primary" large class="col-12" @click="validate()" :loading="submit_loading" :disabled="submit_loading">
          {{$t('Submit')}}
        </v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      submit_loading: false,
      categoryLoading: true,
      typeLoading: true,
      valid: false,

      categoryList: [],
      typeList: [],

      categoryId: null,
      type: null,
      title: "",
      description: "",
      followUpCount: "0",
      followUpText: "",


      descriptionRules: [
        v => !!v,
        v => (v && v.length <= 600) || this.$t("The maximum length is 600 characters")
      ],

      followUpTextRules: [
        v => (v.length <= 255) || this.$t("The maximum length is 255 characters")
      ],

    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    submitForm() {
      this.submit_loading = true;
      var exerciseDTO = {
        categoryId: this.categoryId,
        description: this.description,
        followUpCount: this.followUpCount,
        followUpText: this.followUpText,
        title: this.title,
        typeId: this.type.id,
        type: this.type.value,
      };
      apiService.createExercise(exerciseDTO)
      .then(() => {
        this.submit_loading = false;
          this.$swal({
            icon: "success",
            text: this.$t('The exercise was made successfully'),
            confirmButtonText: this.$t('OK'),
          })
          .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
          });
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    
    getAllCategories() {
      apiService.getCategoryExerciseList(0, 200)
        .then((response) => {
          this.categoryList = response.data.content;
          this.categoryList.length ? this.categoryId = this.categoryList[0].id : null
          this.categoryLoading = false;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },  
    getExerciseType() {
      apiService.getExerciseType()
        .then((response) => {
          this.typeList = response.data;
          this.typeLoading = false;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },

  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  created(){
    this.getAllCategories();
    this.getExerciseType();
  }
}
</script>
